







import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class CardTitleWithIcon extends Vue {
  @Prop() icon
  @Prop() title
}
